'use strict';

/**
 * Fired whenever the drawing tool changes. For example, when the Arrow drawing
 * tool changes into the Rectangle drawing tool.
 *
 * @event Autodesk.Viewing.Extensions.MarkupsCore#EVENT_EDITMODE_CHANGED
 * @memberof Autodesk.Viewing.Extensions.MarkupsCore
 * @type {string}
 */
export const EVENT_EDITMODE_CHANGED = "EVENT_EDITMODE_CHANGED";

/**
 * Fired when Edit mode has been enabled, which allows the end user to start
 * drawing markups over the Viewer canvas.
 *
 * @event Autodesk.Viewing.Extensions.MarkupsCore#EVENT_EDITMODE_ENTER
 * @memberof Autodesk.Viewing.Extensions.MarkupsCore
 * @type {string}
 */
export const EVENT_EDITMODE_ENTER = "EVENT_EDITMODE_ENTER";

/**
 * Fired when Edit mode has been disabled, preventing the end user from
 * drawing markups over the Viewer canvas.
 *
 * @event Autodesk.Viewing.Extensions.MarkupsCore#EVENT_EDITMODE_LEAVE
 * @memberof Autodesk.Viewing.Extensions.MarkupsCore
 * @type {string}
 */
export const EVENT_EDITMODE_LEAVE = "EVENT_EDITMODE_LEAVE";

/**
 * Fired when a drawn markup has been selected by the end user with a click command.
 *
 * @event Autodesk.Viewing.Extensions.MarkupsCore#EVENT_MARKUP_SELECTED
 * @memberof Autodesk.Viewing.Extensions.MarkupsCore
 * @type {object}
 * @property {Markup} markup The selected markup
 */
export const EVENT_MARKUP_SELECTED = "EVENT_MARKUP_SELECTED";

/**
 * Fired when a drawn markup is being dragged over the Viewer canvas.
 *
 * @event Autodesk.Viewing.Extensions.MarkupsCore#EVENT_MARKUP_DRAGGING
 * @memberof Autodesk.Viewing.Extensions.MarkupsCore
 * @type {string}
 */
export const EVENT_MARKUP_DRAGGING = "EVENT_MARKUP_DRAGGING";

/**
 * Internal usage only.
 *
 * @event Autodesk.Viewing.Extensions.MarkupsCore#EVENT_MARKUP_ENTER_EDITION
 * @memberof Autodesk.Viewing.Extensions.MarkupsCore
 * @type {string}
 * @private
 */
export const EVENT_MARKUP_ENTER_EDITION = "EVENT_MARKUP_ENTER_EDITION";

/**
 * Internal usage only.
 *
 * @event Autodesk.Viewing.Extensions.MarkupsCore#EVENT_MARKUP_CANCEL_EDITION
 * @memberof Autodesk.Viewing.Extensions.MarkupsCore
 * @type {string}
 * @private
 */
export const EVENT_MARKUP_CANCEL_EDITION = "EVENT_MARKUP_CANCEL_EDITION";

/**
 * Internal usage only.
 *
 * @event Autodesk.Viewing.Extensions.MarkupsCore#EVENT_MARKUP_DELETE_EDITION
 * @memberof Autodesk.Viewing.Extensions.MarkupsCore
 * @type {string}
 * @private
 */
export const EVENT_MARKUP_DELETE_EDITION = "EVENT_MARKUP_DELETE_EDITION";

/**
 * @typedef {object} EventHistoryChangedData
 The event data to identify the action and target for MarkupCore.EVENT_HISTORY_CHANGED event.
 * @property {string} action A string identifier for the action taken, like redo, undo, cancel, execute, clear
 * @property {number} targetId A number targetId based on the action.
 */

/**
 * Fired whenever a new undo or redo action is available.
 * @event Autodesk.Viewing.Extensions.MarkupsCore#EVENT_HISTORY_CHANGED
 * @memberof Autodesk.Viewing.Extensions.MarkupsCore
 * @type {object}
 * @property {EventHistoryChangedData} data The event data to identify the action and target
 *
 */
export const EVENT_HISTORY_CHANGED = "EVENT_HISTORY_CHANGED";

/**
 * Fired when a markup creation begins.
 * For example, as soon as the user starts dragging with the mouse
 * to draw an arrow on the screen.
 * @event Autodesk.Viewing.Extensions.MarkupsCore#EVENT_EDITMODE_CREATION_BEGIN
 * @memberof Autodesk.Viewing.Extensions.MarkupsCore
 * @type {string}
 */
export const EVENT_EDITMODE_CREATION_BEGIN = "EVENT_EDITMODE_CREATION_BEGIN";

/**
 * Fired when a markup has been created.
 * For example, as soon as the user stops dragging and releases the
 * mouse button to finish drawing an arrow on the screen
 * @event Autodesk.Viewing.Extensions.MarkupsCore#EVENT_EDITMODE_CREATION_END
 * @memberof Autodesk.Viewing.Extensions.MarkupsCore
 * @type {string}
 */
export const EVENT_EDITMODE_CREATION_END = "EVENT_EDITMODE_CREATION_END";

/**
 * Fired when a markup is no longer selected.
 * @event Autodesk.Viewing.Extensions.MarkupsCore#EVENT_MARKUP_DESELECT
 * @memberof Autodesk.Viewing.Extensions.MarkupsCore
 * @type {object}
 * @property {number} markupId The id of the selected markup
 */
export const EVENT_MARKUP_DESELECT = "EVENT_MARKUP_DESELECT";

/**
 * The selected markup is being modified, i.e, resizing, rotating, moving around
 * @event Autodesk.Viewing.Extensions.MarkupsCore#EVENT_EDITFRAME_EDITION_START
 * @memberof Autodesk.Viewing.Extensions.MarkupsCore
 * @type {string}
 */
export const EVENT_EDITFRAME_EDITION_START = "EVENT_EDITFRAME_EDITION_START";

/**
 * The selected markup is no longer being modified
 * @event Autodesk.Viewing.Extensions.MarkupsCore#EVENT_EDITFRAME_EDITION_END
 * @memberof Autodesk.Viewing.Extensions.MarkupsCore
 * @type {string}
 */
export const EVENT_EDITFRAME_EDITION_END = "EVENT_EDITFRAME_EDITION_END";